/**
 * layer style
 */

html #layuicss-layer{display: none; position: absolute; width: 1989px;}

/* common */
.layui-layer-shade, .layui-layer{position:fixed; _position:absolute; pointer-events: auto;}
.layui-layer-shade{top:0; left:0; width:100%; height:100%; _height:expression(document.body.offsetHeight+"px");}
.layui-layer{-webkit-overflow-scrolling: touch;}
.layui-layer{top:150px; left: 0; margin:0; padding:0; background-color:#fff; -webkit-background-clip: content; border-radius: 2px; box-shadow: 1px 1px 50px rgba(0,0,0,.3);}
.layui-layer-close{position:absolute;}
.layui-layer-content{position:relative;}
.layui-layer-border{border: 1px solid #B2B2B2; border: 1px solid rgba(0,0,0,.1); box-shadow: 1px 1px 5px rgba(0,0,0,.2);}
.layui-layer-setwin span,
.layui-layer-btn a{display: inline-block; vertical-align: middle; *display: inline; *zoom:1; }

.layui-layer-move{display: none; position: fixed; *position: absolute; left: 0px; top: 0px; width: 100%; height: 100%; cursor: move; opacity: 0; filter:alpha(opacity=0); background-color: #fff; z-index: 2147483647;}
.layui-layer-resize{position: absolute; width: 15px; height: 15px; right: 0; bottom: 0; cursor: se-resize;}

/* 动画 */
.layer-anim{-webkit-animation-fill-mode: both; animation-fill-mode: both; -webkit-animation-duration:.3s; animation-duration:.3s;}

@-webkit-keyframes layer-bounceIn { /* 默认 */
	0% {opacity: 0; -webkit-transform: scale(.5); transform: scale(.5)}
	100% {opacity: 1; -webkit-transform: scale(1); transform: scale(1)}
}
@keyframes layer-bounceIn {
	0% {opacity: 0; -webkit-transform: scale(.5); -ms-transform: scale(.5); transform: scale(.5)}
	100% {opacity: 1; -webkit-transform: scale(1); -ms-transform: scale(1); transform: scale(1)}
}
.layer-anim-00{-webkit-animation-name: layer-bounceIn;animation-name: layer-bounceIn}

@-webkit-keyframes layer-zoomInDown{0%{opacity:0;-webkit-transform:scale(.1) translateY(-2000px);transform:scale(.1) translateY(-2000px);-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out}60%{opacity:1;-webkit-transform:scale(.475) translateY(60px);transform:scale(.475) translateY(60px);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}}@keyframes layer-zoomInDown{0%{opacity:0;-webkit-transform:scale(.1) translateY(-2000px);-ms-transform:scale(.1) translateY(-2000px);transform:scale(.1) translateY(-2000px);-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out}60%{opacity:1;-webkit-transform:scale(.475) translateY(60px);-ms-transform:scale(.475) translateY(60px);transform:scale(.475) translateY(60px);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}}.layer-anim-01{-webkit-animation-name:layer-zoomInDown;animation-name:layer-zoomInDown}

@-webkit-keyframes layer-fadeInUpBig{0%{opacity:0;-webkit-transform:translateY(2000px);transform:translateY(2000px)}100%{opacity:1;-webkit-transform:translateY(0);transform:translateY(0)}}@keyframes layer-fadeInUpBig{0%{opacity:0;-webkit-transform:translateY(2000px);-ms-transform:translateY(2000px);transform:translateY(2000px)}100%{opacity:1;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0)}}.layer-anim-02{-webkit-animation-name:layer-fadeInUpBig;animation-name:layer-fadeInUpBig}

@-webkit-keyframes layer-zoomInLeft{0%{opacity:0;-webkit-transform:scale(.1) translateX(-2000px);transform:scale(.1) translateX(-2000px);-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out}60%{opacity:1;-webkit-transform:scale(.475) translateX(48px);transform:scale(.475) translateX(48px);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}}@keyframes layer-zoomInLeft{0%{opacity:0;-webkit-transform:scale(.1) translateX(-2000px);-ms-transform:scale(.1) translateX(-2000px);transform:scale(.1) translateX(-2000px);-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out}60%{opacity:1;-webkit-transform:scale(.475) translateX(48px);-ms-transform:scale(.475) translateX(48px);transform:scale(.475) translateX(48px);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}}.layer-anim-03{-webkit-animation-name:layer-zoomInLeft;animation-name:layer-zoomInLeft}

@-webkit-keyframes layer-rollIn{0%{opacity:0;-webkit-transform:translateX(-100%) rotate(-120deg);transform:translateX(-100%) rotate(-120deg)}100%{opacity:1;-webkit-transform:translateX(0px) rotate(0deg);transform:translateX(0px) rotate(0deg)}}@keyframes layer-rollIn{0%{opacity:0;-webkit-transform:translateX(-100%) rotate(-120deg);-ms-transform:translateX(-100%) rotate(-120deg);transform:translateX(-100%) rotate(-120deg)}100%{opacity:1;-webkit-transform:translateX(0px) rotate(0deg);-ms-transform:translateX(0px) rotate(0deg);transform:translateX(0px) rotate(0deg)}}.layer-anim-04{-webkit-animation-name:layer-rollIn;animation-name:layer-rollIn}

@keyframes layer-fadeIn{0%{opacity:0}100%{opacity:1}}.layer-anim-05{-webkit-animation-name:layer-fadeIn;animation-name:layer-fadeIn}

@-webkit-keyframes layer-shake{0%,100%{-webkit-transform:translateX(0);transform:translateX(0)}10%,30%,50%,70%,90%{-webkit-transform:translateX(-10px);transform:translateX(-10px)}20%,40%,60%,80%{-webkit-transform:translateX(10px);transform:translateX(10px)}}@keyframes layer-shake{0%,100%{-webkit-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0)}10%,30%,50%,70%,90%{-webkit-transform:translateX(-10px);-ms-transform:translateX(-10px);transform:translateX(-10px)}20%,40%,60%,80%{-webkit-transform:translateX(10px);-ms-transform:translateX(10px);transform:translateX(10px)}}.layer-anim-06{-webkit-animation-name:layer-shake;animation-name:layer-shake}@-webkit-keyframes fadeIn{0%{opacity:0}100%{opacity:1}}

/* 从上往下 */
@keyframes layer-slide-down {
  from {
    transform: translate3d(0,-100%,0);
  } to {
    transform: translate3d(0,0,0);
  }
}
@keyframes layer-slide-down-out {
  from {
    transform: translate3d(0,0,0);
  } to {
    transform: translate3d(0,-100%,0);
  }
}
.layer-anim-slide-down{animation-name: layer-slide-down}
.layer-anim-slide-down-out{animation-name: layer-slide-down-out}

/* 从右往左 */
@keyframes layer-slide-left {
  from {
    transform: translate3d(100%,0,0);
  } to {
    transform: translate3d(0,0,0);
  }
}
@keyframes layer-slide-left-out {
  from {
    transform: translate3d(0,0,0);
  } to {
    transform: translate3d(100%,0,0);
  }
}
.layer-anim-slide-left{animation-name: layer-slide-left}
.layer-anim-slide-left-out{animation-name: layer-slide-left-out}

/* 从下往上 */
@keyframes layer-slide-up {
  from {
    transform: translate3d(0,100%,0);
  } to {
    transform: translate3d(0,0,0);
  }
}
@keyframes layer-slide-up-out {
  from {
    transform: translate3d(0,0,0);
  } to {
    transform: translate3d(0,100%,0);
  }
}
.layer-anim-slide-up{animation-name: layer-slide-up}
.layer-anim-slide-up-out{animation-name: layer-slide-up-out}

/* 从左往右 */
@keyframes layer-slide-right {
  from {
    transform: translate3d(-100%,0,0);
  } to {
    transform: translate3d(0,0,0);
  }
}
@keyframes layer-slide-right-out {
  from {
    transform: translate3d(0,0,0);
  } to {
    transform: translate3d(-100%,0,0);
  }
}
.layer-anim-slide-right{animation-name: layer-slide-right;}
.layer-anim-slide-right-out{animation-name: layer-slide-right-out;}



/* 标题栏 */
.layui-layer-title{padding: 0 81px 0 16px; height: 50px; line-height: 50px; border-bottom:1px solid #F0F0F0; font-size: 14px; color:#333; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; border-radius: 2px 2px 0 0;}
.layui-layer-setwin{position:absolute; right: 15px; *right:0; top: 16px; font-size:0; line-height: initial;}
.layui-layer-setwin span{position:relative; width: 16px; height: 16px; line-height: 18px; margin-left: 10px; text-align: center; font-size: 16px; cursor: pointer; color: #000; _overflow: hidden; box-sizing: border-box;}
.layui-layer-setwin .layui-layer-min:before{content: ''; position: absolute; width: 12px; border-bottom: 1px solid #2E2D3C; left: 50%; top: 50%; margin: -0.5px 0 0 -6px; cursor: pointer; _overflow:hidden;}
.layui-layer-setwin .layui-layer-min:hover:before{background-color: #2D93CA}
.layui-layer-setwin .layui-layer-max:before,
.layui-layer-setwin .layui-layer-max:after{content: ''; position: absolute; left: 50%; top: 50%; z-index: 1; width: 9px; height: 9px; margin: -5px 0 0 -5px; border: 1px solid #2E2D3C;}
.layui-layer-setwin .layui-layer-max:hover:before,
.layui-layer-setwin .layui-layer-max:hover:after{border-color: #2D93CA;}
.layui-layer-setwin .layui-layer-min:hover:before{background-color: #2D93CA}
.layui-layer-setwin .layui-layer-maxmin:before,
.layui-layer-setwin .layui-layer-maxmin:after{width: 7px; height: 7px; margin: -3px 0 0 -3px; background-color: #fff;}
.layui-layer-setwin .layui-layer-maxmin:after{z-index: 0; margin: -5px 0 0 -1px;}
.layui-layer-setwin .layui-layer-close{cursor: pointer;}
.layui-layer-setwin .layui-layer-close:hover{opacity:0.7;}
.layui-layer-setwin .layui-layer-close2{position:absolute; right: -28px; top: -28px; color: #fff; background-color: #787878; padding: 3px; border: 3px solid; width: 28px; height: 28px; font-size: 16px; font-weight: bolder; border-radius: 50%; margin-left: 0; *right:-18px; _display:none;}
.layui-layer-setwin .layui-layer-close2:hover{opacity: unset; background-color: #3888f6;}

/* 按钮栏 */
.layui-layer-btn{text-align: right; padding: 0 15px 12px; pointer-events: auto; user-select: none; -webkit-user-select: none;}
.layui-layer-btn a{height: 30px; line-height: 30px; margin: 5px 5px 0; padding: 0 16px; border: 1px solid #dedede; background-color: #fff; color: #333; border-radius: 2px; font-weight: 400; cursor: pointer; text-decoration: none; box-sizing: border-box;}
.layui-layer-btn a:hover{opacity: 0.9; text-decoration: none;}
.layui-layer-btn a:active{opacity: 0.8;}
.layui-layer-btn .layui-layer-btn0{border-color: transparent; background-color: #1E9FFF; color:#fff;}
.layui-layer-btn-l{text-align: left;}
.layui-layer-btn-c{text-align: center;}

/* 定制化 */
.layui-layer-dialog{min-width: 240px;}
.layui-layer-dialog .layui-layer-content{position: relative; padding: 16px; line-height: 24px; word-break: break-all; overflow:hidden; font-size:14px; overflow-x: hidden; overflow-y:auto;}
.layui-layer-dialog .layui-layer-content .layui-layer-face{position: absolute; top: 18px; left: 16px; color: #959595; font-size: 32px; _left: -40px;}
.layui-layer-dialog .layui-layer-content .layui-icon-tips{color: #F39B12;}
.layui-layer-dialog .layui-layer-content .layui-icon-success{color: #16b777;}
.layui-layer-dialog .layui-layer-content .layui-icon-error{top: 19px; color: #FF5722;}
.layui-layer-dialog .layui-layer-content .layui-icon-question{color: #FFB800;}
.layui-layer-dialog .layui-layer-content .layui-icon-lock{color: #787878;}
.layui-layer-dialog .layui-layer-content .layui-icon-face-cry{color: #FF5722;}
.layui-layer-dialog .layui-layer-content .layui-icon-face-smile{color: #16b777;}

.layui-layer-rim{border:6px solid #8D8D8D; border:6px solid rgba(0,0,0,.3); border-radius:5px; box-shadow: none;}
.layui-layer-msg{min-width:180px; border:1px solid #D3D4D3; box-shadow: none;}
.layui-layer-hui{min-width:100px;  background-color: #000; filter:alpha(opacity=60); background-color: rgba(0,0,0,0.6); color: #fff; border:none;}
.layui-layer-hui .layui-layer-close{color: #fff;}
.layui-layer-hui .layui-layer-content{padding: 11px 24px; text-align: center;}
.layui-layer-dialog .layui-layer-padding{padding: 18px 24px 18px 58px; text-align: left;}
.layui-layer-page .layui-layer-content{position:relative; overflow:auto;}
.layui-layer-page .layui-layer-btn,.layui-layer-iframe .layui-layer-btn{padding-top:10px;}
.layui-layer-nobg{background:none;}
.layui-layer-iframe iframe{display: block; width: 100%;}

.layui-layer-loading{border-radius:100%; background:none;  box-shadow:none;  border:none;}
.layui-layer-loading .layui-layer-content{width: 76px; height: 38px; line-height: 38px; text-align: center;}
.layui-layer-loading-icon{font-size: 38px; color: #959595;}
.layui-layer-loading2{text-align: center;}
.layui-layer-loading-2{position: relative; height: 38px;}
.layui-layer-loading-2:before,
.layui-layer-loading-2:after{content: ''; position: absolute; left: 50%; top: 50%; width: 38px; height: 38px; margin: -19px 0 0 -19px; border-radius: 50%; border: 3px solid #d2d2d2; box-sizing: border-box;}
.layui-layer-loading-2:after{border-color: transparent; border-left-color: #1E9FFF;}


.layui-layer-tips{background: none; box-shadow:none; border:none;}
.layui-layer-tips .layui-layer-content{position: relative; line-height: 22px; min-width: 12px; padding: 8px 15px; font-size: 12px; _float:left; border-radius: 2px; box-shadow: 1px 1px 3px rgba(0,0,0,.2); background-color: #000; color: #fff;}
.layui-layer-tips .layui-layer-close{right:-2px; top:-1px;}
.layui-layer-tips i.layui-layer-TipsG{ position:absolute;  width:0; height:0; border-width:8px; border-color:transparent; border-style:dashed; *overflow:hidden;}
.layui-layer-tips i.layui-layer-TipsT, .layui-layer-tips i.layui-layer-TipsB{left:5px; border-right-style:solid; border-right-color: #000;}
.layui-layer-tips i.layui-layer-TipsT{bottom:-8px;}
.layui-layer-tips i.layui-layer-TipsB{top:-8px;}
.layui-layer-tips i.layui-layer-TipsR, .layui-layer-tips i.layui-layer-TipsL{top: 5px; border-bottom-style:solid; border-bottom-color: #000;}
.layui-layer-tips i.layui-layer-TipsR{left:-8px;}
.layui-layer-tips i.layui-layer-TipsL{right:-8px;}

/* 内置 skin */
.layui-layer-lan .layui-layer-title{background:#4476A7; color:#fff; border: none;}
.layui-layer-lan .layui-layer-btn{padding: 5px 10px 10px; border-top:1px solid #E9E7E7}
.layui-layer-lan .layui-layer-btn a{background: #fff; border-color: #E9E7E7; color: #333;}
.layui-layer-lan .layui-layer-btn .layui-layer-btn1{background:#C9C5C5;}
.layui-layer-molv .layui-layer-title{background: #009f95; color:#fff; border: none;}
.layui-layer-molv .layui-layer-btn a{background: #009f95; border-color: #009f95;}
.layui-layer-molv .layui-layer-btn .layui-layer-btn1{background:#92B8B1;}
.layui-layer-lan .layui-layer-setwin .layui-icon,
.layui-layer-molv .layui-layer-setwin .layui-icon{color: #fff;}

/* Windows 10 风格主题  */
.layui-layer-win10{border: 1px solid #aaa; box-shadow: 1px 1px 6px rgba(0,0,0,.3); border-radius: none;}
.layui-layer-win10 .layui-layer-title{height: 32px; line-height: 32px; padding-left: 8px; border-bottom: none; font-size: 12px;}
.layui-layer-win10 .layui-layer-setwin{right: 0; top: 0;}
.layui-layer-win10 .layui-layer-setwin span{margin-left: 0; width: 32px; height: 32px; padding: 8px;}
.layui-layer-win10.layui-layer-page .layui-layer-setwin span{width: 38px;}
.layui-layer-win10 .layui-layer-setwin span:hover{background-color: #E5E5E5;}
.layui-layer-win10 .layui-layer-setwin span.layui-icon-close:hover{background-color: #E81123; color: #fff;}
.layui-layer-win10.layui-layer-dialog .layui-layer-content{padding: 8px 16px 32px; color: #0033BC;}
.layui-layer-win10.layui-layer-dialog .layui-layer-padding{padding-top: 18px; padding-left: 58px;}
.layui-layer-win10 .layui-layer-btn{padding: 5px 5px 10px; border-top:1px solid #DFDFDF; background-color: #F0F0F0;}
.layui-layer-win10 .layui-layer-btn a{height: 20px; line-height: 18px; background-color: #E1E1E1; border-color: #ADADAD; color: #000; font-size: 12px; transition: all .3s;}
.layui-layer-win10 .layui-layer-btn a:hover{border-color: #2A8EDD; background-color: #E5F1FB;}
.layui-layer-win10 .layui-layer-btn .layui-layer-btn0{border-color: #0078D7;}


/**
 
 @Name: layer拓展样式
 
 */

/* prompt模式 */
.layui-layer-prompt .layui-layer-input{display: block; width: 260px; height: 36px; margin: 0 auto; line-height: 30px; padding-left: 10px; border: 1px solid #e6e6e6; color: #333;}
.layui-layer-prompt textarea.layui-layer-input{width: 300px; height: 100px; line-height: 20px; padding: 6px 10px;}
.layui-layer-prompt .layui-layer-content{padding: 16px;}
.layui-layer-prompt .layui-layer-btn{padding-top: 0;}

/* tab模式 */
.layui-layer-tab{box-shadow:1px 1px 50px rgba(0,0,0,.4);}
.layui-layer-tab .layui-layer-title{padding-left:0; overflow: visible;}
.layui-layer-tab .layui-layer-title span{position:relative; display: inline-block; vertical-align: top; border-left: 1px solid transparent; border-right: 1px solid transparent; min-width:80px; max-width: 300px; padding:0 16px; text-align:center; cursor:default; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; cursor: pointer;}
.layui-layer-tab .layui-layer-title span.layui-this{height: 51px; border-left-color: #eee; border-right-color: #eee; background-color: #fff; z-index: 10;}
.layui-layer-tab .layui-layer-title span:first-child{border-left-color: transparent;}
.layui-layer-tabmain{line-height:24px; clear: both;}
.layui-layer-tabmain .layui-layer-tabli{display:none;}
.layui-layer-tabmain .layui-layer-tabli.layui-this{display: block;}

/* photos */
.layui-layer-photos{background: none; box-shadow: none;}
.layui-layer-photos .layui-layer-content{overflow: visible; text-align: center;}
.layui-layer-photos .layer-layer-photos-main img{position: relative; width:100%; display: inline-block; *display:inline; *zoom:1; vertical-align:top;}
.layui-layer-photos-prev,
.layui-layer-photos-next{position: fixed; top: 50%; width: 52px; height: 52px; line-height: 52px; margin-top: -26px; cursor: pointer; font-size: 52px; color: #717171;}
.layui-layer-photos-prev{left: 32px;}
.layui-layer-photos-next{right: 32px;}
.layui-layer-photos-prev:hover,
.layui-layer-photos-next:hover{color: #959595;}

.layui-layer-photos-toolbar{position: fixed; left: 0; right: 0; bottom: 0; width: 100%; height: 52px; line-height: 52px; background-color: #000\9; filter: Alpha(opacity=60); background-color: rgba(0,0,0,.32); color: #fff; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-size:0;}
.layui-layer-photos-toolbar > *{display:inline-block; vertical-align: top; padding: 0 16px; font-size: 12px; color: #fff; *display:inline; *zoom: 1;}
.layui-layer-photos-toolbar *{font-size: 12px;}
.layui-layer-photos-header{top: 0; bottom: auto;}
.layui-layer-photos-header > span{cursor: pointer;}
.layui-layer-photos-header > span:hover{background-color: rgba(51,51,51,.32);}
.layui-layer-photos-header .layui-icon{font-size: 18px;}
.layui-layer-photos-footer > h3{max-width: 65%; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
.layui-layer-photos-footer a:hover{text-decoration: underline;}
.layui-layer-photos-footer em{font-style: normal;}

/* 关闭动画 */
@-webkit-keyframes layer-bounceOut {
  100% {opacity: 0; -webkit-transform: scale(.7); transform: scale(.7)}
  30% {-webkit-transform: scale(1.05); transform: scale(1.05)}
  0% {-webkit-transform: scale(1); transform: scale(1);}
}
@keyframes layer-bounceOut {
  100% {opacity: 0; -webkit-transform: scale(.7); -ms-transform: scale(.7); transform: scale(.7);}
  30% {-webkit-transform: scale(1.05); -ms-transform: scale(1.05); transform: scale(1.05);}
  0% {-webkit-transform: scale(1); -ms-transform: scale(1);transform: scale(1);}
}
.layer-anim-close{-webkit-animation-name: layer-bounceOut; animation-name: layer-bounceOut; -webkit-animation-fill-mode: both; animation-fill-mode: both; -webkit-animation-duration:.2s; animation-duration:.2s;}
